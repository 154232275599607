import React, { FC, useCallback, useState } from 'react';

import { ButtonWithLoading } from '../../interface-adapters/global/ButtonWithLoading';
import { routes } from '../../domain/routes';
import { Layout } from '../Layout';
import { titles } from '../../config/titles';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from '../../interface-adapters/navigation/router';

interface Props {
  onAcceptTerms?: () => void;
  onDownloadCard: () => void;
}

export const DownloadCardPage: FC<Props> = ({ onDownloadCard, onAcceptTerms }) => {
  const [termsChecked, setTermsChecked] = useState(false);
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.acceptTerms' });

  const handleChange = (checked: boolean) => {
    setTermsChecked(checked);
    onAcceptTerms && onAcceptTerms();
  };
  const canDownloadCard = termsChecked;

  const handleDownload = useCallback(() => {
    if (termsChecked) {
      onDownloadCard();
    }
  }, [termsChecked, onDownloadCard]);

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <div className="l-row l-row--mt-16">
            <ButtonWithLoading onClick={handleDownload} isDisabled={!canDownloadCard}>
              {t('buttonLabel')}
            </ButtonWithLoading>
          </div>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">{t('header', { issuerTitle: titles.issuerTitle })}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">
          <Trans
            i18nKey="text" // optional -> fallbacks to defaults if not provided
            t={t}
            values={{ issuerTitle: titles.issuerTitle }}
            components={{ italic: <i />, under: <u /> }}
          />
        </div>
      </div>
      <div className="l-row l-row--mt-80">
        <div className="c-checkbox">
          <label className="c-checkbox__label">
            <input
              type="checkbox"
              name="checkbox"
              hidden
              checked={termsChecked}
              onChange={(e) => handleChange(e.target.checked)}
            />
            <span className="c-checkbox__icon" />
            <span className="c-checkbox__title">
              <Trans
                i18nKey="checkboxText" // optional -> fallbacks to defaults if not provided
                t={t}
                values={{ issuerTitle: titles.issuerTitle }}
                components={{
                  ln: <Link to={routes.terms} target="_blank" className="c-checkbox__link" />,
                }}
              />
            </span>
          </label>
        </div>
      </div>
    </Layout>
  );
};
