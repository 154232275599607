import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { routes } from '../../domain/routes';
import { getIsAuthenticated } from '../auth/authStore';
import { IPropsWithRequiredChildren } from '../../boundries/utilityTypes';

export const IfAuthorized: FC<IPropsWithRequiredChildren> = ({ children }) => {
  const isAuth = useSelector(getIsAuthenticated);

  return isAuth ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: routes.signIn,
      }}
    />
  );
};
