import React, { FC } from 'react';

interface Props {
  onClick: () => void;
}

export const CrossButton: FC<Props> = ({ onClick }) => (
  <button className="c-close" type="button" onClick={onClick}>
    <span className="c-close__inner">
      <svg className="icon icon--cross">
        <use xlinkHref="/img/_icons.svg#icon--cross" />
      </svg>
    </span>
  </button>
);
