import React, { FC } from 'react';
import { Navigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { routes } from '../../domain/routes';
import { IPropsWithRequiredChildren } from '../../boundries/utilityTypes';

export const IfProfileActivation: FC<IPropsWithRequiredChildren> = ({ children }) => {
  const activation = useSelector(getActivation);
  const isSignRequiredStepsComplete = activation?.nextStepSkippable || activation?.completed;

  return !isSignRequiredStepsComplete ? (
    children
  ) : (
    <Navigate
      to={{
        pathname: routes.profile,
      }}
    />
  );
};
