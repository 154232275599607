import { isNull } from 'lodash';
import { getToken, setToken, updateUserData } from './authStore';
import { Store } from '@reduxjs/toolkit';
import { ISDK } from '@roketus/loyalty-js-sdk';

export const updateUserDataServiceInit = (store: Store, sdk: ISDK) => {
  const { dispatch, getState } = store;
  let prevValue: string | null = null;
  store.subscribe(() => {
    const state = getState();
    const jwt = getToken(state);

    if (!isNull(jwt) && jwt !== prevValue) {
      prevValue = jwt;
      dispatch(setToken(jwt));
      dispatch(updateUserData());
      sdk.user.setUserToken(jwt);
    }
  });
};
