import { isNumber } from 'lodash';

export const CODE_ERROR = {
  UNKNOWN: 0,
  PUSH_SHOW: 1,
};
export const CODE_LOG = {
  INSTALL: 1000,
  ACTIVATE: 1001,
  PUSH: 1002,
  PUSH_DATA: 1003,
  FAKE_FETCH: 1004,
  NOTIFICATION_UNSUPPORTED: 1005,
  NOTIFICATION_REJECTED: 1006,
  NOTIFICATION_SHOW_ACCEPTED: 1007,
  NOTIFICATION_SHOW_AFTER_REQUEST: 1008,
  NOTIFICATION_REJECTED_AFTER_REQUEST: 1009,
  NOTIFICATION_DISPLAY: 1010,
};

const textByCode = {
  [CODE_LOG.INSTALL]: 'Service Worker installed',
  [CODE_LOG.ACTIVATE]: 'Service Worker activated',
  [CODE_LOG.PUSH]: 'Push event',
  [CODE_LOG.PUSH_DATA]: 'Push data',
  [CODE_LOG.FAKE_FETCH]: 'Fake fetch',
  [CODE_LOG.NOTIFICATION_UNSUPPORTED]: 'Notifications are not supported',
  [CODE_LOG.NOTIFICATION_REJECTED]: 'Notifications are rejected',
  [CODE_LOG.NOTIFICATION_SHOW_ACCEPTED]: 'Notifications are accepted',
  [CODE_LOG.NOTIFICATION_SHOW_AFTER_REQUEST]: 'Notifications are accepted after request',
  [CODE_LOG.NOTIFICATION_REJECTED_AFTER_REQUEST]: 'Notifications are rejected after request',
  [CODE_LOG.NOTIFICATION_DISPLAY]: 'Notification displayed',
  [CODE_ERROR.UNKNOWN]: 'Unknown error',
  [CODE_ERROR.PUSH_SHOW]: 'Push show error',
};

export const formatLog = (code: unknown, ...payload: any[]) => {
  if (isNumber(code) && !textByCode[code]) {
    return [textByCode[code], ...payload];
  }

  return [code, ...payload];
};
