import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useNavigate } from '../interface-adapters/navigation/router';
import { routes } from '../domain/routes';
import { IPropsWithChildren } from '../boundries/utilityTypes';

interface Props extends IPropsWithChildren {
  footer?: ReactNode;
}

let timer: ReturnType<typeof setTimeout>;

const useDevModeEffct = () => {
  const [clicks, setClicks] = useState(0);
  const navigate = useNavigate();

  const handler = () => {
    setClicks(clicks + 1);
    clearTimeout(timer);
    timer = setTimeout(() => {
      setClicks(0);
    }, 1000);
  };

  useEffect(() => {
    if (clicks > 10) navigate(routes.dev);
  }, [clicks, navigate]);

  return handler;
};
export const Layout: FC<Props> = ({ children, footer }) => {
  const onClick = useDevModeEffct();

  return (
    <div className="l-page" onClick={onClick}>
      <main className="l-main">{children}</main>
      {footer}
    </div>
  );
};
