import React, { FC, useCallback } from 'react';

import { Layout } from '../../components/Layout';
import { useAppDispatch } from '../store/hooks';
import { setProfile, skipProfile } from './signupStore';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { useSelector } from 'react-redux';
import { getActivation } from '../dbStore/dbStore';
import { useParams } from '../navigation/router';
import { regExpIsNameValid } from '../user/utils';
import { DEMO_PROFILE_CONTINUE_CLICK } from '../../domain/specs/analyticsActionCodes';
import { IMessageBus } from '@roketus/web-toolkit';
import { getDependency } from '../diContext';
import { useDispatchWithHistory } from '../actionWithHistory';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { useForm } from 'react-hook-form';

type TFormData = { firstName: string; lastName: string };
export const SetupProfilePage: FC = () => {
  const activation = useSelector(getActivation);
  const dispatch = useAppDispatch();
  const dispatchWithHistory = useDispatchWithHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.setProfile' });

  const params = useParams<{ userId?: string }>();
  useRedirectOnAuthStepChangeEffct();
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { control, handleSubmit, watch, formState } = useForm<TFormData>({ mode: 'onBlur' });

  let userId = params.userId;
  const canSetProfile = formState.isValid;
  const messageBus = getDependency('messageBus') as IMessageBus;

  const onSubmit = ({ firstName, lastName }: TFormData) => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_PROFILE_CONTINUE_CLICK,
      source: 'ProfilePage',
    });

    dispatchWithHistory(setProfile, {
      firstName,
      lastName,
      userId,
    });
  };

  const handleOnSkipProfileCLick = useCallback(() => {
    dispatch(skipProfile());
  }, [dispatch]);

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSubmit(onSubmit)} isDisabled={!canSetProfile}>
            {t('continueButton')}
          </ButtonWithLoading>
          {activation && activation.nextStepSkippable && (
            <div className="l-row l-row--mt-16">
              <button className="c-link" onClick={handleOnSkipProfileCLick}>
                {t('skipButton')}
              </button>
            </div>
          )}
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <button className="c-close" type="button">
          <span className="c-close__inner">
            <svg className="icon icon--cross">
              <use xlinkHref="img/_icons.svg#icon--cross" />
            </svg>
          </span>
        </button>
        <div className="l-row">
          <div className="c-title">{t('header')}</div>
        </div>
        <div className="l-row">
          <Input
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('firstNameLabel') as string }) as string,
              },
              pattern: { value: regExpIsNameValid, message: tError('firstNameError') as string },
            }}
            control={control}
            name="firstName"
            label={t('firstNameLabel') as string}
          />
        </div>
        <div className="l-row l-row--mt-16">
          <Input
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('lastNameLabel') as string }) as string,
              },
              pattern: { value: regExpIsNameValid, message: tError('lastNameError') as string },
            }}
            control={control}
            name="lastName"
            label={t('lastNameLabel') as string}
          />
        </div>
        <div className="l-row">{t('cardPreview')}</div>
        <div className="l-row l-row--mt-16">
          <div className="c-card">
            <div className="c-card__name">
              {watch('firstName')} {watch('lastName')}
            </div>
            <div className="c-card__number">1234 - 1234 - 1234</div>
          </div>
        </div>
      </form>
    </Layout>
  );
};
