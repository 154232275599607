import { ActionCreatorWithPayload, createAction } from '@reduxjs/toolkit';
import { useAppDispatch } from './store/hooks';
import { useNavigate } from './navigation/router';

export type ActionWithHistory<P> = {
  data: P;
  navigate: (to: string) => void;
};

export const createActionWithHistory = <P>(name: string) => {
  return createAction<ActionWithHistory<P>>(name);
};

export const useDispatchWithHistory = <P = any>() => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  return (action: ActionCreatorWithPayload<ActionWithHistory<P>>, data: P) => {
    dispatch(
      action({
        data,
        navigate,
      }),
    );
  };
};
