import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { getActivation } from '../../interface-adapters/dbStore/dbStore';
import { signupPageByState, signupStepsLabels } from '../../interface-adapters/signup/consts';
import { DEMO_PROFILE_SET_CLICK } from '../../domain/specs/analyticsActionCodes';
import { SignupStep } from '../../domain/entities/signup';
import { IMessageBus } from '@roketus/web-toolkit';
import { getDependency } from '../../interface-adapters/diContext';
import { useNavigate } from '../../interface-adapters/navigation/router';
import { useTranslation } from 'react-i18next';

export const OptionalActivationStepsButton = () => {
  const navigate = useNavigate();
  const activation = useSelector(getActivation);
  const nextStep = activation?.nextStep; //todo TS
  const nextStepLabelKey = signupStepsLabels[nextStep || SignupStep.Basic];
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.step' });
  const nextStepLabel = t(nextStepLabelKey);
  const messageBus = getDependency('messageBus') as IMessageBus;

  const handleClickButton = useCallback(() => {
    if (!nextStep) return; //todo TS remove
    const redirectUrl = signupPageByState[nextStep];

    if (SignupStep.Profile === nextStep) {
      messageBus.send({
        type: 'GAanalyticsEvent',
        message: DEMO_PROFILE_SET_CLICK,
        source: 'ProfilePage',
      });
    }

    navigate(redirectUrl);
  }, [navigate, nextStep, messageBus]);

  return (
    <>
      {!activation?.completed && (
        <button className="c-link" data-id="next-step-button" onClick={handleClickButton}>
          {nextStepLabel}
        </button>
      )}
    </>
  );
};
