import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { persistStore } from 'redux-persist';
import { reducer as toastrReducer } from 'react-redux-toastr';

import { rootSaga } from '../saga/rootSaga';
import { signupReducer } from '../signup/signupStore';
import { dbReducer } from '../dbStore/dbStore';
import { globalReducer } from '../global/globalStore';
import { authReducer } from '../auth/authStore';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    signup: signupReducer,
    db: dbReducer,
    global: globalReducer,
    toastr: toastrReducer,
    auth: authReducer,
  },
  middleware: [sagaMiddleware] as const,
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
