import { StateSubject } from '@roketus/web-toolkit';

const stateMachine = new StateSubject<{ isLoaded: boolean }>({
  isLoaded: false,
});
export const windowLoadService = {
  state: stateMachine.state$,
};

export const init = () => {
  window.addEventListener('load', function () {
    stateMachine.setState({ isLoaded: true });
  });
};
