import { Ii18nScheme } from './en';

const uk: Ii18nScheme = {
  translation: {
    common: {
      phoneInput: {
        placeholder: '',
      },
      errors: {
        'user not found': 'Ой помилка, користувача не знайдено',
        'wrong phone code': 'Ой помилка, невірний код',
        isRequired: "{{label}} є обов'язковим",
        unknownTitle: 'Помилка',
        unknownText: 'Упс, щось пішло не так!',
        firstNameError: 'Невірний формат',
        lastNameError: 'Невірний формат',
      },
      date: {
        month: {
          January: 'Січень',
          February: 'Лютий',
          March: 'Березень',
          April: 'Квітень',
          May: 'Травень',
          June: 'Червень',
          July: 'Липень',
          August: 'Серпень',
          September: 'Вересень',
          October: 'Жовтень',
          November: 'Листопад',
          December: 'Грудень',
        },
      },
    },
    signIn: {
      index: {
        header: 'Увійти',
        text: 'Номер телефону',
        buttonLabel: 'Приєднатись, якщо ще не з нами',
        confirmationLabel: 'Код підтвердження',
      },
      validateOtp: {
        header: 'Будь ласка, введіть код підтвердження',
        text: '6 цифр, що надійшли на ваш номер телефону',
        secondText: 'xxx xxxx {{phoneNumberSlice}}.',
        changePhoneNumber: 'Змінити номер телефону',
        noCode: 'Не приходить код?',
        resendCode: 'Надіслати код ще раз',
        waitResend: 'Зачекайте 30 секунд та спробуйте ще',
        buttonLabel: 'Підтвердити',
      },
    },
    signUp: {
      acceptTerms: {
        header: 'Отримуйте переваги разом з картою лояльності {{issuerTitle}}',
        secondHeader: 'Натискаючи «Прийняти», ви погоджуєтесь з',
        text: 'Заощаджуйте 5% кожного разу, коли ви робите покупки в {{issuerTitle}}.<br />Отримуйте доступ до пропозицій та знижок тільки для вас.',
        checkboxText: 'Я погоджуюсь з <ln>умовами та політикою конфіденційності</ln>',
        buttonLabel: 'Створити аккаунт',
      },
      setPhone: {
        header: 'Введіть номер телефону',
        text: 'Ми надішлемо вам код підтвердження',
        buttonLabel: 'Відправити код {{countdownTitle}}',
      },
      phoneActivation: {
        title: 'Вам вдалося!',
        header:
          'Вітаємо в програмі лояльності {{issuerTitle}} з усіма її перевагами.<br />Насолоджуйтесь широким вибором бонусів, заощаджень, знижок та інших пропозицій.',
        bonuses: 'НЕЗАБАРОМ ВИ ОТРИМАЄТЕ ВАШІ БОНУСИ!',
        moreBonuses: 'Заробити більше бонусів',
        bonusesProgram: 'Бонусна Програма',
        complete: 'Закінчіть активацію, щоб отримати більше бонусів.',
        buttonLabel: 'Продовжити',
      },
      setProfile: {
        firstNameError: 'Невірний формат',
        firstNameLabel: 'Ім’я',
        lastNameError: 'Невірний формат',
        lastNameLabel: 'Прізвище',
        continueButton: 'Підтвердити',
        skipButton: '',
        header: 'Надайте інформацію про себе',
        cardPreview: 'Попередній вигляд карти',
      },
      setBirthday: {
        header: 'Встановити дату народження',
        continueButton: 'Підтвердити',
        dayLabel: 'Число',
        monthLabel: 'Місяць',
        yearLabel: 'Рік',
      },
      step: {
        Basic: '',
        Profile: 'Введіть ім’я та прізвище',
        SetPhone: '',
        PhoneActivation: '',
        PhoneActivationSuccess: '',
        SetEmail: 'Підтвердити електронну пошту',
        EmailActivation: '',
        SetBirthday: 'Встановити дату народження',
        Completed: 'Профіль',
      },
    },
    profile: {
      title: 'Профіль',
      subtitle: 'Вітаю, {{name}}',
      mainText:
        'Ваша карта лояльності {{issuerTitle}} завжди з вами. Використовуйте її для отримання переваг та знижок в {{issuerTitle}}.',
      dateLabel: 'Дійсний до: ',
      downloadCardForIos: 'Скачати картку для iOS',
      downloadCardForAndroid: 'Скачати картку для Android',
      bonusesLabel: 'Ваші бонуси: {{balance}}',
      referral: {
        title: '{{issuerTitle}} реферальна програма',
        text: 'Запросіть своїх друзів та отримуйте бонуси за кожного з них!',
        buttonLabel: 'Запросити',
        copy: 'Скопіювати посилання',
        copied: 'Скопійовано!',
      },
      updateName: 'Змінити ім’я та прізвище',
      updatePhone: 'Змінити номер телефону',
      updateEmail: 'Змінити електронну пошту',
      subscribe: 'Підписатися на сповіщення?',
      logout: 'Вийти',
    },
    changeProfile: {
      title: 'Змінити ім’я та прізвище',
      buttonLabel: 'Змінити',
      firstNameLabel: 'Ім’я',
      lastNameLabel: 'Прізвище',
    },
    changePhoneNumber: {
      title: 'Змінити номер телефону',
      buttonLabel: 'Змінити',
      updateButtonLabel: 'Змінити {{time}}',
    },
    changeEmail: {
      title: 'Змінити електронну пошту',
      buttonLabel: 'Змінити',
    },
    emailVerification: {
      send: 'Надіслати підтвердження',
      skip: 'Пропустити',
      title: 'Підтвердьте адресу електронної пошти!',
      message: 'Введіть свою електронну адресу.',
      email: 'Eлектронна пошта',
    },
    system: {
      otp: {
        required: `Обов'язкове поле`,
        pattern: 'Невірний формат',
        minLength: 'Мінімальна довжина 6 символів',
        maxLength: 'Максимальна довжина 6 символів',
      },
    },
  },
};

export default uk;
