import { createSelector, createSlice } from '@reduxjs/toolkit';
import { GlobalState } from '../../boundries/storeState/GlobalState';
import { RootState } from '../../boundries/storeState/RootState';

const initialState: GlobalState = {
  isRequestInProgress: false,
  isClientInitialized: false,
};

const signupSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    startRequest: (state) => {
      state.isRequestInProgress = true;
    },
    finishRequest: (state) => {
      state.isRequestInProgress = false;
    },
    finishInitializeClient: (state) => {
      state.isClientInitialized = true;
    },
    startAndroidCardRequest: (state) => {
      state.isAndroidCardRequestInProgress = true;
    },
    finishAndroidCardRequest: (state) => {
      state.isAndroidCardRequestInProgress = false;
    },
    startIOSCardRequest: (state) => {
      state.isIOSCardRequestInProgress = true;
    },
    finishIOSCardRequest: (state) => {
      state.isIOSCardRequestInProgress = false;
    },
  },
});

export const globalReducer = signupSlice.reducer;

export const { startRequest, finishRequest, startIOSCardRequest, finishIOSCardRequest, startAndroidCardRequest, finishAndroidCardRequest, finishInitializeClient } = signupSlice.actions;
const getState = (state: RootState) => state.global;

export const getIsRequestInProgress = createSelector(
  getState,
  (state) => state.isRequestInProgress,
);

export const getIsClientInitialized = createSelector(
  getState,
  (state) => state.isClientInitialized,
);

export const getIsAndroidCardRequestInProgress = createSelector(
  getState,
  (state) => state.isAndroidCardRequestInProgress,
);

export const getIsIOSCardRequestInProgress = createSelector(
  getState,
  (state) => state.isIOSCardRequestInProgress,
);

