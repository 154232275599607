import { Store } from '@reduxjs/toolkit';
import { merge } from 'lodash';
import { isEmpty, get, isUndefined } from 'lodash';
import { RecursivePartial } from '../boundries/utilityTypes';
import { IMessageBus } from '@roketus/web-toolkit';
import { IUserAnalyticsService } from '../boundries/IUserAnalyticsService';
import { ISDK } from '@roketus/loyalty-js-sdk';
import { i18n } from 'i18next';

interface DiContext {
  store: Store;
  logger: {
    error: (error: string, component: string, e?: Error) => void;
    loggedRequest: (
      loggerData: { componentName: string; error: string },
      API: any,
      ...options: any[]
    ) => Iterable<any>;
  };
  messageBus: IMessageBus;
  userAnalyticsService: IUserAnalyticsService;
  sdk: ISDK;
  i18n: i18n;
}

// This will throw runtime error if some try to use uninitialized, but will
// allow destruct in the global context
let ctx: DiContext | {} = {};

export const getCtx = () => {
  if (isEmpty(ctx)) throw Error('Context is not initialized');

  return ctx as DiContext;
};

export const setCtx = (newCtx: DiContext) => {
  ctx = newCtx;
};

export const updateCtx = (newCtx: RecursivePartial<DiContext>) => {
  ctx = merge(ctx, newCtx);
};

export const getDependency = <T>(dependencyName: keyof DiContext): T => {
  const dependency = get(ctx, dependencyName);
  if (isUndefined(dependency)) throw Error('Context is not initialized');

  return dependency;
};
