import { FC, useCallback } from 'react';

import { DownloadCardPage as CDownloadCardPage } from '../../components/pages/DownloadCardPage';
import { useAppDispatch } from '../store/hooks';
import { createCard } from './cardStore';

export const DownloadCardPage: FC = () => {
  const dispatch = useAppDispatch();

  const handleDownload = useCallback(() => {
    dispatch(createCard());
  }, [dispatch]);

  return <CDownloadCardPage onDownloadCard={handleDownload} />;
};
