import { takeLatest, call, put } from 'redux-saga/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import {
  acceptTermsByUser,
  activatePhone,
  resendActivationCode,
  setPhone,
  setProfile,
  skipProfile,
  setEmail,
  skipEmail,
  activateEmail,
  setBirthdate,
} from './signupStore';
import {
  acceptTermsAPI,
  activatePhoneAPI,
  resendActivationCodeAPI,
  setPhoneAPI,
  setProfileAPI,
  skipProfileAPI,
  setEmailAPI,
  skipEmailAPI,
  activateEmailAPI,
  skipEmailActivationAPI,
} from '../api/signup';
import {
  ActivatePhoneActionPayload,
  SetPhoneActionPayload,
  SetProfileActionPayload,
  StartActivationTermsActionPayload,
  SetEmailActionPayload,
  SetBirthdateActionPayload,
} from '../../boundries/actionsPayloads/signupActionPayloads';
import { saveJsonApiResponseToDb, saveJsonApiResponseToDbFunction } from '../sharedSagas';
import { JsonApiResponseDTO } from '../../boundries/responseDTO/jsonApi';
import { ActionWithHistory } from '../actionWithHistory';
import { routes } from '../../domain/routes';
import { setToken } from '../auth/authStore';
import { handleError } from '../error/utils';
import { finishRequest, startRequest } from '../global/globalStore';
import { ActivationEntity } from '../../domain/entities/signup';
import { IHTTPClientResponse } from '@roketus/web-toolkit';
import { getCtx } from '../diContext';
import { activationMode } from '../../config/signupConfig';
import {
  DEMO_SIGNUP_BIRTHDAY_SET,
  DEMO_SIGNUP_EMAIL_SET,
  DEMO_SIGNUP_NAME_SET,
  DEMO_SIGNUP_OTP_VALIDATED,
  DEMO_SIGNUP_PHONE_SET,
} from '../../domain/specs/analyticsActionCodes';

function* acceptTermsByUserSaga(action: PayloadAction<StartActivationTermsActionPayload>) {
  try {
    const referralId = action.payload.referralId;
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      acceptTermsAPI,
      referralId,
    );

    const token = response.data.included[0].attributes.token;

    if (token) {
      yield put(setToken(token));
    }

    yield call<saveJsonApiResponseToDbFunction<ActivationEntity>>(
      saveJsonApiResponseToDb,
      response.data,
    );
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* setProfileSaga(action: PayloadAction<ActionWithHistory<SetProfileActionPayload>>) {
  try {
    const { navigate, data } = action.payload;
    const { messageBus } = getCtx();

    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      setProfileAPI,
      data,
    );

    const token = response.data.included[0].attributes.token;

    if (token) {
      yield put(setToken(token));
    }

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    messageBus.send({
      type: 'analyticsEvent',
      message: DEMO_SIGNUP_NAME_SET,
      source: 'signupSaga',
    });
    yield call(navigate, routes.signupSetPhone);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

const normalizeDate = ({ day, month, year }: { day: string; month: string; year: string }) =>
  `${year}-${month}-${day}`;
function* setBirthdateSaga(action: PayloadAction<ActionWithHistory<SetBirthdateActionPayload>>) {
  try {
    const { sdk, messageBus } = getCtx();

    const { data } = action.payload;

    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      sdk.signup.setBirthdate,
      normalizeDate(data),
      activationMode,
    );

    const token = response.data.included[0].attributes.token;

    if (token) {
      yield put(setToken(token));
    }

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    messageBus.send({
      type: 'analyticsEvent',
      message: DEMO_SIGNUP_BIRTHDAY_SET,
      source: 'signupSaga',
      data: new Date(normalizeDate(data)),
    });
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* skipProfileSaga() {
  try {
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      skipProfileAPI,
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* setPhoneSaga(action: PayloadAction<SetPhoneActionPayload>) {
  try {
    const phoneNumber = action.payload;
    const { messageBus } = getCtx();

    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      setPhoneAPI,
      phoneNumber,
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    messageBus.send({
      type: 'analyticsEvent',
      message: DEMO_SIGNUP_PHONE_SET,
      source: 'signupSaga',
    });
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* activatePhoneSaga(action: PayloadAction<ActionWithHistory<ActivatePhoneActionPayload>>) {
  try {
    const phoneNumber = action.payload.data.otp;
    const { messageBus } = getCtx();

    const navigate = action.payload.navigate;
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      activatePhoneAPI,
      parseInt(phoneNumber, 10),
    );

    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    messageBus.send({
      type: 'analyticsEvent',
      message: DEMO_SIGNUP_OTP_VALIDATED,
      source: 'signupSaga',
    });
    yield call(navigate, routes.signupPhoneActivationSuccess);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* resendActivationCodeSaga() {
  try {
    yield put(startRequest());
    yield call(resendActivationCodeAPI);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* setEmailSaga(action: PayloadAction<ActionWithHistory<SetEmailActionPayload>>) {
  try {
    const {
      data: { email },
      navigate,
    } = action.payload;
    const { messageBus } = getCtx();

    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      setEmailAPI,
      email,
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
    messageBus.send({
      type: 'analyticsEvent',
      message: DEMO_SIGNUP_EMAIL_SET,
      source: 'signupSaga',
    });
    yield call(navigate, routes.signupCheckInbox);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* skipEmailSaga(action: PayloadAction<ActionWithHistory<{}>>) {
  try {
    const { navigate } = action.payload;
    yield put(startRequest());
    const responseSkipEmail: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      skipEmailAPI,
    );
    const responseEmailActivation: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> =
      yield call(skipEmailActivationAPI);
    yield call<saveJsonApiResponseToDbFunction<unknown>>(
      saveJsonApiResponseToDb,
      responseSkipEmail.data,
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(
      saveJsonApiResponseToDb,
      responseEmailActivation.data,
    );
    yield call(navigate, routes.profile);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

function* activateEmailSaga() {
  try {
    yield put(startRequest());
    const response: IHTTPClientResponse<JsonApiResponseDTO<ActivationEntity>> = yield call(
      activateEmailAPI,
    );
    yield call<saveJsonApiResponseToDbFunction<unknown>>(saveJsonApiResponseToDb, response.data);
  } catch (e) {
    yield call(handleError, e as Error);
  } finally {
    yield put(finishRequest());
  }
}

export function* signupSaga() {
  yield takeLatest(acceptTermsByUser, acceptTermsByUserSaga);
  yield takeLatest(setProfile, setProfileSaga);
  yield takeLatest(setBirthdate, setBirthdateSaga);
  yield takeLatest(skipProfile, skipProfileSaga);
  yield takeLatest(setPhone, setPhoneSaga);
  yield takeLatest(activatePhone, activatePhoneSaga);
  yield takeLatest(resendActivationCode, resendActivationCodeSaga);
  yield takeLatest(setEmail, setEmailSaga);
  yield takeLatest(skipEmail, skipEmailSaga);
  yield takeLatest(activateEmail, activateEmailSaga);
}
