export const DEMO_NAME_UPDATE_CLICK = 'DEMO_NAME_UPDATE_CLICK';
export const DEMO_PHONE_UPDATE_CLICK = 'DEMO_PHONE_UPDATE_CLICK';
export const DEMO_EMAIL_UPDATE_CLICK = 'DEMO_EMAIL_UPDATE_CLICK';
export const DEMO_PROFILE_SET_CLICK = 'DEMO_PROFILE_SET_CLICK';
export const DEMO_LOGOUT_CLICK = 'DEMO_LOGOUT_CLICK';
export const DEMO_PROFILE_CONTINUE_CLICK = 'DEMO_PROFILE_CONTINUE_CLICK';

export const DEMO_SIGNUP_PHONE_SET = 'DEMO_SIGNUP_PHONE_SET'; // PhoneAdded
export const DEMO_SIGNUP_OTP_VALIDATED = 'DEMO_SIGNUP_OTP_VALIDATED'; // CodeVerified
export const DEMO_SIGNUP_NAME_SET = 'DEMO_SIGNUP_NAME_SET'; // NameAdded
export const DEMO_SIGNUP_BIRTHDAY_SET = 'DEMO_SIGNUP_BIRTHDAY_SET'; // BirthDayAdded (also send calculated Age to Amplitude as a property)
export const DEMO_SIGNUP_EMAIL_SET = 'DEMO_SIGNUP_EMAIL_SET'; // EmailAdded
