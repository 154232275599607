import React, { FC } from 'react';
import { noop } from 'lodash';
import { ButtonProps } from '../../components/Button/types';
import { Button } from '../../components/Button';
import { useSelector } from 'react-redux';
import { getIsRequestInProgress } from './globalStore';

export const ButtonWithLoading: FC<Omit<ButtonProps, 'isLoading'>> = ({ onClick, loadingSelector, ...rest }) => {
  const isLoading: boolean | undefined = useSelector(loadingSelector || getIsRequestInProgress);
  const handleClick = isLoading ? noop : onClick;

  return <Button {...rest} onClick={handleClick} isLoading={isLoading} />;
};
