import { FC } from 'react';

import { Layout } from '../../Layout';
import { ReactComponent as Brand } from './logo_text.svg';
import styles from './LoadingPage.module.scss';

export const LoadingPage: FC = () => {
  return (
    <Layout>
      <div className={styles.container}>
        <Brand />
      </div>
    </Layout>
  );
};
