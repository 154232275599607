import React, { FC } from 'react';

import { Layout } from '../../../components/Layout';
import { useRedirectOnAuthStepChangeEffct } from '../../sharedHooks';
import { ButtonWithLoading } from '../../global/ButtonWithLoading';
import { useDispatchWithHistory } from '../../actionWithHistory';
import { useTranslation } from 'react-i18next';
import { Input } from '../../../components/Input';
import { useForm } from 'react-hook-form';
import { Select } from '../../../components/Select';
import { days, months } from './SetBirthdayPage.config';
import { setBirthdate } from '../signupStore';

type TFormData = { day: string; month: string; year: string };
export const SetBirthdayPage: FC = () => {
  const dispatchWithHistory = useDispatchWithHistory();
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.setBirthday' });

  useRedirectOnAuthStepChangeEffct();
  const { t: tError } = useTranslation('translation', { keyPrefix: 'common.errors' });
  const { t: tDate } = useTranslation('translation', { keyPrefix: 'common.date.month' });
  const { control, handleSubmit, formState } = useForm<TFormData>({ mode: 'onBlur' });

  const onSubmit = (data: TFormData) => {
    console.log(data);
    dispatchWithHistory(setBirthdate, data);
  };

  const memoMonths = React.useMemo(
    () => months.map(({ label, value }) => ({ label: tDate(label), value })),
    [tDate],
  );

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSubmit(onSubmit)} isDisabled={!formState.isValid}>
            {t('continueButton')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <button className="c-close" type="button">
          <span className="c-close__inner">
            <svg className="icon icon--cross">
              <use xlinkHref="img/_icons.svg#icon--cross" />
            </svg>
          </span>
        </button>
        <div className="l-row">
          <div className="c-title">{t('header')}</div>
        </div>
        <div className="l-row">
          <Select
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('dayLabel') as string }) as string,
              },
            }}
            defaultValue={'01'}
            control={control}
            name="day"
            label={t('dayLabel') as string}
            options={days}
          />
        </div>
        <div className="l-row">
          <Select
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('monthLabel') as string }) as string,
              },
            }}
            defaultValue={'01'}
            control={control}
            name="month"
            label={t('monthLabel') as string}
            options={memoMonths}
          />
        </div>
        <div className="l-row">
          <Input
            rules={{
              required: {
                value: true,
                message: tError('isRequired', { label: t('yearLabel') as string }) as string,
              },
              minLength: 4,
              maxLength: 4,
            }}
            control={control}
            name="year"
            placeholder="1980"
            label={t('yearLabel') as string}
          />
        </div>
      </form>
    </Layout>
  );
};
