import { getCtx } from '../diContext';

export const changePhoneAPI = (phoneNumber: string) => {
  const { sdk } = getCtx();
  return sdk.user.changePhone(phoneNumber);
};

export const changeEmailAPI = (email: string) => {
  const { sdk } = getCtx();
  return sdk.user.changeEmail(email);
};

export const validateNewPhoneOtpAPI = (code: number) => {
  const { sdk } = getCtx();
  return sdk.user.validateNewPhoneOtp(code);
};

export const changeProfileAPI = (firstName: string, lastName: string) => {
  const { sdk } = getCtx();
  return sdk.user.changeProfileData(firstName, lastName);
};

export const subscribeToNotificationsAPI = (subscription: PushSubscriptionJSON) => {
  const { sdk } = getCtx();
  return sdk.user.subscribeToWebPushNotifications(subscription);
};

export const sendPushNotificationAPI = (userId: string, title: string, message: string) => {
  const { sdk } = getCtx();
  return sdk.issuer.sendPushNotification(userId, title, message);
};
