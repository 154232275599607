import React, { FC } from 'react';
import styles from './Loader.module.scss';
import { ReactComponent as Logo } from './logo.svg';
import cn from 'classnames';

interface Props {
  isActive?: boolean;
}

export const Loader: FC<Props> = () => {
  return (
    <div>
      <div className={styles.container}>
        <div className={cn(styles.blob, styles.green)}></div>
        <div className={styles.logoBg} />
        <div className={styles.logo}>
          <Logo />
        </div>
      </div>
    </div>
  );
};
