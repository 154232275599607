import React, { FC } from 'react';

import { useAppDispatch } from '../store/hooks';
import { useDispatchWithHistory } from '../actionWithHistory';
import { resendActivationCode } from '../signup/signupStore';
import { activatePhone } from './userSaga';
import { routes } from '../../domain/routes';
import { useNavigate, useParams } from '../navigation/router';
import { ActivateNewPhoneActionPayload } from '../../boundries/actionsPayloads/userActionPayloads';
import { ValidateOtpPage } from '../../components/pages/ValidateOtpPage';

export const ChangePhoneActivationPage: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dispatchWithHistory = useDispatchWithHistory<ActivateNewPhoneActionPayload>();
  const { phoneNumber = '' } = useParams<{ phoneNumber?: string }>();

  const sendCode = (code: string) => {
    dispatchWithHistory(activatePhone, {
      code,
    });
  };

  const resendActivationFn = () => dispatch(resendActivationCode());
  const handleBack = () => {
    navigate(routes.profile);
  };

  return (
    <ValidateOtpPage
      onSendOtpClick={sendCode}
      onCrossClick={handleBack}
      phoneNumber={phoneNumber}
      onResendActivationCode={resendActivationFn}
    />
  );
};
