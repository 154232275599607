import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isUndefined, noop } from 'lodash';

import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../../interface-adapters/global/ButtonWithLoading';
import { OtpInput } from '../../interface-adapters/forms/OtpInput';
import { CrossButton } from '../CrossButton';
import { PhoneNumberPage } from './PhoneNumberPage';
import { useTranslation } from 'react-i18next';

const DISPLAY_LAST_NUMBERS_QUANTITY = 4;
const RESEND_SMS_TIMEOUT = 30 * 1000;

interface Props {
  onSendOtpClick: (otp: string) => void;
  onCrossClick?: () => void;
  onResendActivationCode?: () => void;
  phoneNumber: string;
}

interface FormData {
  otp: string;
}

const lengthMessage = 'otp should be 6 symbols';
const validateOptions = {
  required: 'otp is required',
  pattern: {
    value: /^\d*$/,
    message: 'only numbers are allowed',
  },

  minLength: {
    message: lengthMessage,
    value: 6,
  },
  maxLength: {
    message: lengthMessage,
    value: 6,
  },
};

export const ValidateOtpPage: FC<Props> = ({
  onCrossClick,
  onSendOtpClick,
  phoneNumber: defaultPhone,
  onResendActivationCode,
}) => {
  const { t } = useTranslation('translation', { keyPrefix: 'signIn.validateOtp' });
  const { t: st } = useTranslation('translation', { keyPrefix: 'system' });
  const validateOptionsWithTranslation = {
    ...validateOptions,
  };
  validateOptionsWithTranslation.required = st('otp.required');
  validateOptionsWithTranslation.pattern.message = st('otp.pattern');
  validateOptionsWithTranslation.minLength.message = st('otp.minLength');
  validateOptionsWithTranslation.maxLength.message = st('otp.maxLength');

  const [phoneNumber, setPhoneNumber] = useState(defaultPhone);
  const [isChangePhone, setIsChangePhone] = useState(false);
  const phoneNumberSlice = useMemo(() => {
    const phoneLength = phoneNumber.length;
    if (phoneLength < DISPLAY_LAST_NUMBERS_QUANTITY) return phoneNumber;

    const startIdx = phoneLength - DISPLAY_LAST_NUMBERS_QUANTITY;
    return phoneNumber.slice(startIdx, phoneLength);
  }, [phoneNumber]);

  const [resendInProgress, setResendInProgress] = useState(false);

  const hasCodeResend = !isUndefined(onResendActivationCode);
  const resendActivationFn = hasCodeResend
    ? () => {
        setResendInProgress(true);
        onResendActivationCode && onResendActivationCode();
      }
    : noop;

  const handleChangePhoneNumber = () => {
    setIsChangePhone(true);
  };

  const handlePhoneNumberSend = (phoneNumber: string) => {
    setPhoneNumber(phoneNumber);
    setIsChangePhone(false);
  };

  const handlePhoneNumberReturn = () => {
    setIsChangePhone(false);
  };

  const handleCrossClick = () => {
    if (!isUndefined(onCrossClick)) {
      onCrossClick();
    } else {
      handleChangePhoneNumber();
    }
  };

  useEffect(() => {
    if (resendInProgress) {
      const to = setTimeout(() => setResendInProgress(false), RESEND_SMS_TIMEOUT);

      return () => {
        clearTimeout(to);
      };
    }
  }, [resendInProgress]);

  const onSubmit = (data: FormData) => {
    onSendOtpClick(data.otp);
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormData>({
    mode: 'onChange',
  });

  return (
    <>
      {isChangePhone && (
        <PhoneNumberPage
          onPhoneNumberSend={handlePhoneNumberSend}
          onCrossClick={handlePhoneNumberReturn}
          defaultPhone={phoneNumber}
        />
      )}
      {!isChangePhone && (
        <Layout
          footer={
            <footer className="l-footer">
              <ButtonWithLoading onClick={handleSubmit(onSubmit)} isDisabled={!isValid}>
                {t('buttonLabel')}
              </ButtonWithLoading>
            </footer>
          }
        >
          <CrossButton onClick={handleCrossClick} />
          <div className="l-row">
            <div className="c-title">{t('header')}</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-text">
              {t('text')}
              <br />
              <b>{t('secondText', { phoneNumberSlice })}</b>
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <span className="c-link" onClick={handleChangePhoneNumber}>
              {t('changePhoneNumber')}
            </span>
          </div>
          <div className="l-row">
            <form onSubmit={handleSubmit(onSubmit)}>
              <OtpInput
                initFormOption={register('otp', validateOptionsWithTranslation)}
                error={errors.otp}
              />
            </form>
          </div>
          <div className="l-row">{t('noCode')}</div>
          {hasCodeResend && (
            <div className="l-row l-row--mt-16">
              {resendInProgress ? (
                t('waitResend')
              ) : (
                <button className="c-link" onClick={resendActivationFn}>
                  {t('resendCode')}
                </button>
              )}
            </div>
          )}
        </Layout>
      )}
    </>
  );
};
