import { isNil, isUndefined } from 'lodash';

import { getEnv } from '../config/env';
import { subscribeToNotificationsAPI } from './api/user';
import { isNotificationsSupported } from '../service-worker/helpers';

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

const getApplicationServerKey = () => {
  const applicationServerPublicKey = getEnv().REACT_APP_PUSH_SERVER_PUBLIC_KEY || '';
  return urlBase64ToUint8Array(applicationServerPublicKey);
};

export const subscribeToPushManager = async () => {
  if (!isNotificationsSupported()) {
    console.log('subscribeToPushManager:: Notifications are not supported');
    return;
  }

  const registration = await navigator?.serviceWorker?.getRegistration();
  console.log('subscribeToPushManager:: checking registration', registration, typeof registration);

  if (isUndefined(registration)) {
    console.log('subscribeToPushManager:: service worker not registered');
    return 'service worker not registered';
  }

  const subscription = await registration?.pushManager?.getSubscription();
  console.log('subscribeToPushManager:: checking subscription', subscription, typeof subscription);
  if (subscription !== null) {
    console.log('subscribeToPushManager:: already subscribed');
    return 'already subscribed';
  }

  try {
    console.log(
      'subscribeToPushManager:: no subscription found',
      subscription,
      typeof subscription,
    );
    const newSubscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: getApplicationServerKey(),
    });
    const subscriptionJSON: PushSubscriptionJSON = newSubscription?.toJSON();
    if (!subscriptionJSON.endpoint) return false;
    await subscribeToNotificationsAPI(subscriptionJSON);
    return 'subscribed';
  } catch (unknownError) {
    const error = unknownError as Error;
    console.error('PushManager subscribe error: ', error);
    return error.message;
  }
};

export const unSubscribeFromPushManager = async () => {
  console.log('unSubscribeFromPushManager:: trying to unsubscribe');

  const registration = await navigator.serviceWorker.ready;

  if (isUndefined(registration)) {
    console.log('unSubscribeFromPushManager:: service worker not registered');
    return 'service worker not registered';
  }

  const pushManager = registration.pushManager;

  if (isNil(pushManager)) {
    console.log('unSubscribeFromPushManager:: pushManager is null', pushManager);
    return 'service worker not unregistered';
  }

  const subscription = await pushManager.getSubscription();
  try {
    await subscription?.unsubscribe();
    console.log('unSubscribeFromPushManager:: unsubscribed');
    return 'unsubscribe';
  } catch (unknownError) {
    const error = unknownError as Error;

    console.log('unSubscribeFromPushManager:: ', error);
    return error.message;
  }
};
