const en = {
  translation: {
    common: {
      phoneInput: {
        placeholder: 'Enter phone number',
      },
      errors: {
        'user not found': 'User not found',
        'wrong phone code': 'Wrong phone code',
        isRequired: '{{label}} is required',
        unknownTitle: 'Error',
        unknownText: 'Oops, something went wrong',
        firstNameError: 'First Name is not valid',
        lastNameError: 'Last Name is not valid',
      },
      date: {
        month: {
          January: 'January',
          February: 'February',
          March: 'March',
          April: 'April',
          May: 'May',
          June: 'June',
          July: 'July',
          August: 'August',
          September: 'September',
          October: 'October',
          November: 'November',
          December: 'December',
        },
      },
    },
    signIn: {
      index: {
        header: 'Sign In',
        text: 'Choose Your Country Code and enter your phone number',
        buttonLabel: 'Sign Up',
        confirmationLabel: 'Send Confirmation Code',
      },
      validateOtp: {
        header: 'Please enter your verification code',
        text: 'Enter the 6-digit code we have sent to',
        secondText: 'xxx xxxx {{phoneNumberSlice}}.',
        changePhoneNumber: 'Edit phone number',
        noCode: 'Did not receive verification code?',
        resendCode: 'Resend code',
        waitResend: 'Wait 30 seconds to try again',
        buttonLabel: 'Verify code',
      },
    },
    signUp: {
      acceptTerms: {
        header: 'Benefit with {{issuerTitle}} loyalty card',
        secondHeader: 'By clicking “Accept”, you agree to the',
        text: 'Earn 5% each time you shop at {{titles.issuerTitle}}.<br />Get access to deals and offers just for you.',
        checkboxText: 'I agree with <ln>TERMS AND CONDITIONS</ln>',
        buttonLabel: 'Create account',
      },
      setPhone: {
        header: 'Please enter your mobile phone number',
        text: 'We will send you a 6-digit verification code',
        buttonLabel: 'Send Code {{countdownTitle}}',
      },
      phoneActivation: {
        title: 'You’re in!',
        header:
          'Welcome to {{issuerTitle}} loyalty program and all its benefits.<br />Enjoy a wide range of earnings, offers and more.',
        bonuses: 'YOU WILL RECEIVE YOUR BONUSES SHORTLY!',
        moreBonuses: 'Earn more bonuses',
        bonusesProgram: 'Bonuses Program',
        complete: 'Complete your activation to add more bonuses.',
        buttonLabel: 'Continue',
      },
      setProfile: {
        firstNameError: 'First Name is not valid',
        firstNameLabel: 'First Name',
        lastNameError: 'Last Name is not valid',
        lastNameLabel: 'Last Name',
        continueButton: 'Continue',
        skipButton: 'Skip',
        header: 'Please enter your name',
        cardPreview: 'Card Preview',
      },
      setBirthday: {
        header: 'Please enter your birthday',
        continueButton: 'Continue',
        dayLabel: 'Day of the month',
        monthLabel: 'Month',
        yearLabel: 'Year',
      },
      step: {
        Basic: '',
        Profile: 'Set Profile',
        SetPhone: '',
        PhoneActivation: '',
        PhoneActivationSuccess: '',
        SetEmail: 'Verify Your Email',
        EmailActivation: '',
        SetBirthday: 'Set Birthday',
        Completed: 'Profile',
      },
    },
    profile: {
      title: 'Your Account',
      subtitle: 'Hello, {{name}}',
      mainText:
        'This is your {{issuerTitle}} loyalty program account where you can check your balance, invite friends and manage your personal information.',
      dateLabel: 'Valid through: ',
      downloadCardForIos: 'Download card for iOS',
      downloadCardForAndroid: 'Download card for Android',
      bonusesLabel: 'Your bonuses: {{balance}}',
      referral: {
        title: '{{issuerTitle}} referral program',
        text: 'Invite your friend and earn 5 bonuses',
        buttonLabel: 'Invite Your Friend',
        copy: 'Copy Url',
        copied: 'Url Copied!',
      },
      updateName: 'Update Name on Card',
      updatePhone: 'Update Phone Number',
      updateEmail: 'Update Email',
      subscribe: 'Do you want to subscribe to notifications?',
      logout: 'Log Out',
    },
    changeProfile: {
      title: 'Update Personal Info',
      buttonLabel: 'Update',
      firstNameLabel: 'First Name',
      lastNameLabel: 'Last Name',
    },
    changePhoneNumber: {
      title: 'Update your mobile phone number',
      buttonLabel: 'Update',
      updateButtonLabel: 'Update {{time}}',
    },
    changeEmail: {
      title: 'Update your email',
      buttonLabel: 'Update',
    },
    emailVerification: {
      send: 'Send Confirmation',
      skip: 'Skip',
      title: 'Verify Email Address!',
      message: 'Please enter your valid email address.',
      email: 'Email',
    },
    system: {
      otp: {
        required: `otp is required`,
        pattern: 'only numbers are allowed',
        minLength: 'Min length 6 symbols',
        maxLength: 'Max length 6 symbols',
      },
    },
  },
};

export type Ii18nScheme = typeof en;

export default en;
