import React, { FC, useCallback, useEffect } from 'react';
import { isNull } from 'lodash';

import { useNavigate } from '../navigation/router';
import { routes } from '../../domain/routes';
import { SuccessPage } from '../../components/SuccessPage';
import { useQuery } from '../sharedHooks';
import { useAppDispatch } from '../store/hooks';
import { setToken } from '../auth/authStore';
import { titles } from '../../config/titles';
import { InviteFriendButton } from '../user/InviteFriendButton';

export const TOKEN_KEY = 'token';
export const EmailVerificationSuccessPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useQuery();
  const token = params.get(TOKEN_KEY);

  const handleCheckBalance = useCallback(() => {
    navigate(routes.profile);
  }, [navigate]);

  useEffect(() => {
    if (!isNull(token)) {
      dispatch(setToken(token));
    }
  }, [token, dispatch]);

  return (
    <SuccessPage buttonTitle="Check Balance" onClick={handleCheckBalance}>
      <div className="l-row">
        <b>YOUR EMAIL HAS BEEN CONFIRMED!</b>
      </div>
      <div className="l-row">
        <div className="c-text">You’ve earned more bonuses, check your balance.</div>
      </div>
      <div className="l-row">
        <div className="c-line" />
      </div>
      <div className="l-row">
        <b>{titles.issuerTitle} referral program</b>
      </div>
      <div className="l-row l-row--mt-16">
        <b>Invite your friend and earn 5 bonuses</b>
        <div className="c-text">
          When new client install {titles.issuerTitle} loyalty card and create new account both you
          and your friend will earn 5 bonuses.
        </div>
      </div>
      <div className="l-row">
        <InviteFriendButton />
      </div>
    </SuccessPage>
  );
};
