import { IMessage, IMessageBus } from '@roketus/web-toolkit';
import { filter } from 'rxjs';
import {
  DEMO_EMAIL_UPDATE_CLICK,
  DEMO_LOGOUT_CLICK,
  DEMO_NAME_UPDATE_CLICK,
  DEMO_PHONE_UPDATE_CLICK,
  DEMO_PROFILE_CONTINUE_CLICK,
  DEMO_PROFILE_SET_CLICK,
  DEMO_SIGNUP_BIRTHDAY_SET,
  DEMO_SIGNUP_EMAIL_SET,
  DEMO_SIGNUP_NAME_SET,
  DEMO_SIGNUP_OTP_VALIDATED,
  DEMO_SIGNUP_PHONE_SET,
} from '../domain/specs/analyticsActionCodes';
import { IUserAnalyticsService } from '../boundries/IUserAnalyticsService';
import { init as amplitudeInit, track } from '@amplitude/analytics-browser';

const amplitudeEventName: Record<string, string> = {
  [DEMO_SIGNUP_PHONE_SET]: 'PhoneAdded',
  [DEMO_SIGNUP_OTP_VALIDATED]: 'CodeVerified',
  [DEMO_SIGNUP_NAME_SET]: 'NameAdded',
  [DEMO_SIGNUP_BIRTHDAY_SET]: 'BirthDayAdded',
  [DEMO_SIGNUP_EMAIL_SET]: 'EmailAdded',
};
const isGTAMessage = (value: IMessage): boolean =>
  [
    DEMO_NAME_UPDATE_CLICK,
    DEMO_PHONE_UPDATE_CLICK,
    DEMO_EMAIL_UPDATE_CLICK,
    DEMO_PROFILE_SET_CLICK,
    DEMO_LOGOUT_CLICK,
    DEMO_PROFILE_CONTINUE_CLICK,
  ].includes(value.message);
const isAmplitudeAnalyticsMessage = (value: IMessage): boolean =>
  [
    DEMO_SIGNUP_PHONE_SET,
    DEMO_SIGNUP_OTP_VALIDATED,
    DEMO_SIGNUP_NAME_SET,
    DEMO_SIGNUP_BIRTHDAY_SET,
    DEMO_SIGNUP_EMAIL_SET,
  ].includes(value.message);

export const init = (messageBus: IMessageBus): IUserAnalyticsService => {
  let user_id: string | undefined;
  const gtag = window.gtag;

  amplitudeInit(process.env.REACT_APP_AMPLITUDE_API_KEY as string, undefined, {
    appVersion: process.env.REACT_APP_VERSION,
    // logLevel: 4,
  });

  messageBus.stream$.pipe(filter(isGTAMessage)).subscribe((msg) => {
    const message = msg as IMessage;
    const send = (eventName: string, value?: string) => {
      gtag('event', eventName, {
        value: value,
      });
    };

    return send(message.message);
  });

  messageBus.stream$.pipe(filter(isAmplitudeAnalyticsMessage)).subscribe((msg) => {
    const message = msg as IMessage;
    const event = amplitudeEventName[message.message];
    if (message.message === DEMO_SIGNUP_BIRTHDAY_SET) {
      // @TODO calc data
    }

    if (!event) return console.error('Event not found for', message.message);

    return track(event);
  });

  return {
    setUserID(userId: string) {
      gtag('set', { user_id: userId });
      user_id = userId;
    },
    getUserID() {
      return user_id || '';
    },
  };
};
