import { FC, useCallback } from 'react';
import { isUndefined } from 'lodash';

import { DownloadCardPage as CDownloadCardPage } from '../../components/pages/DownloadCardPage';
import { useParams } from '../navigation/router';
import { useAppDispatch } from '../store/hooks';
import { createCardByReferral } from './cardStore';

export const DownloadCardByReferralPage: FC = () => {
  const { referralId } = useParams<{ referralId?: string }>();
  const dispatch = useAppDispatch();

  const handleDownload = useCallback(() => {
    if (!isUndefined(referralId)) dispatch(createCardByReferral(referralId));
  }, [dispatch, referralId]);

  return <CDownloadCardPage onDownloadCard={handleDownload} />;
};
