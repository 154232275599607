import { FC, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { useNavigate } from '../navigation/router';
import { SuccessPage } from '../../components/SuccessPage';
import { getActivation } from '../dbStore/dbStore';
import { signupPageByState } from './consts';
import { titles } from '../../config/titles';
import { Trans, useTranslation } from 'react-i18next';

export const PhoneActivationSuccessPage: FC = () => {
  const navigate = useNavigate();

  const activation = useSelector(getActivation);
  const nextStep = activation?.nextStep;
  const { t } = useTranslation('translation', { keyPrefix: 'signUp.phoneActivation' });

  const handleNextStep = useCallback(() => {
    if (!nextStep) return;
    const redirectUrl = signupPageByState[nextStep];

    navigate(redirectUrl);
  }, [navigate, nextStep]);

  return (
    <SuccessPage title={t('title') || ''} buttonTitle={t('buttonLabel')} onClick={handleNextStep}>
      <div className="l-row">
        <div className="c-text">
          <Trans
            i18nKey="header"
            t={t}
            values={{ issuerTitle: titles.issuerTitle }}
            components={{ italic: <i />, under: <u /> }}
          />
        </div>
      </div>
      <div className="l-row">
        <b>{t('bonuses')}</b>
      </div>
      <div className="l-row">
        <div className="c-line" />
      </div>
      <div className="l-row">
        <b>{t('moreBonuses')}</b>
      </div>
      <div className="l-row l-row--mt-16">
        <b>{t('bonusesProgram')}</b>
        <div className="c-text">{t('complete')}</div>
      </div>
    </SuccessPage>
  );
};
