import React, { FC, useEffect, useRef } from 'react';

import { authByJWT } from './authStore';
import { useDispatchWithHistory } from '../actionWithHistory';
import { AuthByJWTActionPayload } from '../../boundries/actionsPayloads/authActionPayloads';
import { useQuery } from '../sharedHooks';

const JWT_KEY = 'jwt';
const GAUID_KEY = 'gauid';

export const SignInByJWT: FC = () => {
  const dispatch = useDispatchWithHistory<AuthByJWTActionPayload>();
  let query = useQuery();
  const jwt = query.get(JWT_KEY);
  const gauid = query.get(GAUID_KEY);
  const activated = useRef(false);

  useEffect(() => {
    if (jwt && gauid) {
      if (!activated.current) {
        activated.current = true;
        dispatch(authByJWT, { jwt, gauid });
      }
    }
  }, [jwt, dispatch, gauid]);

  return <div>Please wait you'll be signed in</div>;
};
