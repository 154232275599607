import { ButtonHTMLAttributes, FC } from 'react';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { getIsAndroidCardRequestInProgress, getIsIOSCardRequestInProgress } from '../global/globalStore';
import { ButtonDownloadCardProps } from '../../components/Button/types';

export const ButtonDownloadCard: FC<ButtonHTMLAttributes<unknown> & ButtonDownloadCardProps> = ({ device, type, onClick, children, ...rest }) => {
  const loadingSelectorMap = {
    iOS: getIsIOSCardRequestInProgress,
    android: getIsAndroidCardRequestInProgress
  };
  const loadingSelector = loadingSelectorMap[device];

  return <ButtonWithLoading {...rest} loadingSelector={loadingSelector} onClick={onClick} type={type}>
    {children}
  </ButtonWithLoading>
};

