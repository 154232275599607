import { get, isUndefined } from 'lodash';
import { call, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { FormattedError } from '../../domain/entities/error';
import { getCtx } from '../diContext';
import { logout } from '../auth/authStore';

export function* handleError(e: Error, componentName: string = 'Unknown') {
  const prettyError: unknown = get(e, 'response.data.data[0]');
  const status = get(e, 'response.status');

  const { logger, i18n } = getCtx();
  if (status === 401) {
    yield put(logout());
    return;
  }

  if (isFormattedError(prettyError)) {
    yield call(
      toastr.error,
      // @TODO fix on BE different text or USE proper error codes
      i18n.t('common.errors.unknownTitle'),
      i18n.t([`common.errors.${prettyError.detail}`, 'common.errors.unknownText']),
    );
    yield call(logger.error, prettyError.title, componentName, e);
  } else {
    yield call(
      toastr.error,
      i18n.t('common.errors.unknownTitle'),
      i18n.t('common.errors.unknownText'),
    );
    yield call(toastr.error, 'Unknown Error', 'Please try again');
    yield call(logger.error, 'Unknown Error', componentName, e);
    console.log(e);
  }
}

const isFormattedError = (data: unknown): data is FormattedError => {
  const code = get(data, 'response_code');

  return !isUndefined(code);
};
