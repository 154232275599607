import { call, put, takeLatest } from 'redux-saga/effects';
import { REHYDRATE } from 'redux-persist/lib/constants';

import { initDi } from '../../initDi';
import { finishInitializeClient } from './globalStore';
import { sdk } from '@roketus/loyalty-js-sdk';

function* initDISaga() {
  try {
    yield call(initDi);
    yield put(finishInitializeClient());
    yield initTelemetrySaga();
  } catch (e) {
    console.log(e);
  }
}

function submitTelemetry() {
  if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

  sdk.telemetry
    .send(process.env.REACT_APP_NAME ?? 'unknown', process.env.REACT_APP_VERSION ?? 'unknown')
    .then(console.log);
}
function* initTelemetrySaga() {
  try {
    yield call(submitTelemetry);
  } catch (e) {
    console.log(e);
  }
}

export function* globalSaga() {
  yield takeLatest(REHYDRATE, initDISaga);
}
