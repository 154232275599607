import { formatLog } from './formatter';

export const logger = {
  log: (...args: any[]) => {
    // @ts-ignore
    const data = formatLog.apply(null, args);
    console.log.apply(console, data);
  },
};

const CHANNEL_NAME = 'rk-pwa';
export const subscribeToSWMessages = () => {
  const bc = new BroadcastChannel(CHANNEL_NAME);

  bc.onmessage = function (ev) {
    const { code, payload } = ev.data;
    logger.log(code, ...payload);
  };
};
