import { getCtx } from '../diContext';
import { LogErrorActionPayload } from '../../boundries/actionsPayloads/loggerActionPayloads';

export const logErrorAPI = (data: LogErrorActionPayload, correlation_id = 'unknown') => {
  const { sdk } = getCtx();

  if (process.env.REACT_APP_ENVIRONMENT === 'local') return;

  return sdk.logger.logError(data, correlation_id);
};
