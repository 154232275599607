import React, { FC, useCallback } from 'react';

import { useNavigate } from '../navigation/router';
import { routes } from '../../domain/routes';
import { ErrorPage } from '../../components/ErrorPage';

export const EmailVerificationErrorPage: FC = () => {
  const navigate = useNavigate();

  const handleCheckBalance = useCallback(() => {
    navigate(routes.profile);
  }, [navigate]);

  return (
    <ErrorPage buttonTitle="Check Balance" onClick={handleCheckBalance}>
      <div className="l-row">
        <b>we can’t verify your email… please try again and double check your email address</b>
      </div>
      <div className="l-row">
        <svg className="icon icon--error">
          <use xlinkHref="/img/_icons.svg#icon--error" />
        </svg>
      </div>
    </ErrorPage>
  );
};
