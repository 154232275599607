import { createAction, PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  createCardAPI,
  createCardByReferralAPI,
  downloadIOSCardAPI,
  downloadAndroidCardAPI,
} from '../api/card';
import {
  finishAndroidCardRequest,
  finishIOSCardRequest,
  finishRequest,
  startAndroidCardRequest,
  startIOSCardRequest,
  startRequest,
} from '../global/globalStore';
import { CreateCardByReferralPayload } from '../../boundries/actionsPayloads/cardActionPayloads';
import { handleError } from '../error/utils';

export const createCard = createAction('card/createCard');
export const createCardByReferral = createAction<CreateCardByReferralPayload>(
  'card/createCardByReferral',
);
export const downloadIOSCardAction = createAction('card/downloadIOSCard');
export const downloadAndroidCardAction = createAction('card/downloadAndroidCard');

function* createCardSaga() {
  try {
    yield put(startRequest());
    yield call(createCardAPI);
  } catch (e) {
    yield call(handleError, e as Error, 'createCardSaga');
  } finally {
    yield put(finishRequest());
  }
}

function* createCardByReferralSaga(action: PayloadAction<CreateCardByReferralPayload>) {
  try {
    yield put(startRequest());
    yield call(createCardByReferralAPI, action.payload);
  } catch (e) {
    yield call(handleError, e as Error, 'createCardByReferralSaga');
  } finally {
    yield put(finishRequest());
  }
}

function* DownloadIOSCardSaga() {
  try {
    yield put(startIOSCardRequest());
    yield call(downloadIOSCardAPI);
  } catch (e) {
    yield call(handleError, e as Error, 'DownloadIOSCardSaga');
  } finally {
    yield put(finishIOSCardRequest());
  }
}

function* DownloadAndroidCardSaga() {
  try {
    yield put(startAndroidCardRequest());
    const response: string = yield call(downloadAndroidCardAPI);
    yield call(window.open, response, '_self');
  } catch (e) {
    yield call(handleError, e as Error, 'DownloadAndroidCardSaga');
  } finally {
    yield put(finishAndroidCardRequest());
  }
}

export function* cardSaga() {
  yield takeLatest(createCard, createCardSaga);
  yield takeLatest(createCardByReferral, createCardByReferralSaga);
  yield takeLatest(downloadIOSCardAction, DownloadIOSCardSaga);
  yield takeLatest(downloadAndroidCardAction, DownloadAndroidCardSaga);
}
