import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { getBalance } from '../dbStore/dbStore';
import { isActivationModeBasic } from './utils';
import { useTranslation } from 'react-i18next';

export const BonusesBalance: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });

  const balance = useSelector(getBalance);
  if (isActivationModeBasic()) {
    return (
      <span data-id="balance-title">{t('bonusesLabel', { balance: balance?.balance || 0 })}</span>
    );
  }
  return null;
};
