import { getCtx } from '../diContext';

export const signInByPhoneAPI = (phoneNumber: string) => {
  const { sdk } = getCtx();
  return sdk.auth.signInByPhone(phoneNumber);
};

export const validatePhoneOtpAPI = (phoneNumber: string, code: number) => {
  const { sdk } = getCtx();
  return sdk.auth.validatePhoneOtp(phoneNumber, code);
};

export const getUserDataAPI = () => {
  const { sdk } = getCtx();
  return sdk.auth.getUserData();
};
