import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { getIsClientInitialized } from './globalStore';
import { IPropsWithChildren } from "../../boundries/utilityTypes";


export const DIBootstrap: FC<IPropsWithChildren> = ({ children }) => {
  const isClientInitialized = useSelector(getIsClientInitialized);
  return isClientInitialized ? <>{children}</> : null;
};
