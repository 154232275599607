import React, { FC } from 'react';
import PhoneInputComponent, { Props } from 'react-phone-number-input';
import './phone-styles.css';
import { useTranslation } from 'react-i18next';

export const PhoneInput: FC<Props<{}>> = (props) => {
  const { t } = useTranslation('translation', { keyPrefix: 'common.phoneInput' });

  return (
    <div className="c-phone">
      <PhoneInputComponent
        {...props}
        placeholder={t('placeholder') ?? ''}
        className="c-phone__input"
        defaultCountry="UA"
        flagUrl="https://catamphetamine.gitlab.io/country-flag-icons/3x2/{XX}.svg"
        international
      />
    </div>
  );
};
