import React, { FC, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isUndefined } from 'lodash';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Button } from '../../components/Button';
import { getActivation, getUser } from '../dbStore/dbStore';
import { obfuscateEmail } from '../user/utils';
import { activateEmail } from './signupStore';
import { useAppDispatch } from '../store/hooks';
import { useNavigate } from '../navigation/router';

export const CheckInboxPage: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const activation = useSelector(getActivation);

  useEffect(() => {
    if (activation && activation.nextStep === 'emailActivation') {
      dispatch(activateEmail());
    }
  }, [activation, dispatch]);

  const handleCheckBalance = useCallback(() => {
    navigate(routes.profile);
  }, [navigate]);

  const user = useSelector(getUser);

  let email = !isUndefined(user) && !user.emailVerified ? obfuscateEmail(user.email) : '';

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <Button onClick={handleCheckBalance}>Your account</Button>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">Check your {email} inbox!</div>
      </div>
      <div className="l-row">
        <div className="c-text">Confirm your email!</div>
      </div>
      <div className="l-row">
        <svg className="icon icon--envelope">
          <use xlinkHref="img/_icons.svg#icon--envelope" />
        </svg>
      </div>
    </Layout>
  );
};
