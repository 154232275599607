export interface AccountType {
  private: 'private';
  public: 'public';
}

export interface Gender {
  mail: 'mail';
  female: 'female';
}

export enum SignupStep {
  Basic = 'basic',
  Profile = 'profile',
  SetPhone = 'setPhone',
  PhoneActivation = 'phoneActivation',
  PhoneActivationSuccess = 'phoneActivationSuccess',
  SetEmail = 'setEmail',
  EmailActivation = 'emailActivation',
  SetBirthday = 'birth',
  Completed = 'completed',
}

export interface ActivationEntity {
  completed: boolean;
  completedRequired: boolean;
  nextStep: SignupStep;
  type: 'basic';
  nextStepSkippable: false;
  token?: string;
}
