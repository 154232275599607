import { SignupStep } from '../../domain/entities/signup';
import { routes } from '../../domain/routes';
import { Ii18nScheme } from '../i18n/en';

export const signupPageByState: Record<SignupStep, string> = {
  [SignupStep.Basic]: routes.signupTerms,
  [SignupStep.Profile]: routes.signupProfile,
  [SignupStep.SetPhone]: routes.signupSetPhone,
  [SignupStep.PhoneActivation]: routes.signupPhoneActivation,
  [SignupStep.PhoneActivationSuccess]: routes.signupPhoneActivationSuccess,
  [SignupStep.SetEmail]: routes.signupSetEmail,
  [SignupStep.EmailActivation]: routes.signupCheckInbox,
  [SignupStep.SetBirthday]: routes.signupSetBirthday,
  [SignupStep.Completed]: routes.profile,
};

export const signupStepsLabels: Record<
  SignupStep,
  keyof Ii18nScheme['translation']['signUp']['step']
> = {
  [SignupStep.Basic]: 'Basic',
  [SignupStep.Profile]: 'Profile',
  [SignupStep.SetPhone]: 'SetPhone',
  [SignupStep.PhoneActivation]: 'PhoneActivation',
  [SignupStep.PhoneActivationSuccess]: 'PhoneActivationSuccess',
  [SignupStep.SetEmail]: 'SetEmail',
  [SignupStep.EmailActivation]: 'EmailActivation',
  [SignupStep.SetBirthday]: 'SetBirthday',
  [SignupStep.Completed]: 'Completed',
};
