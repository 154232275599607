import React, { FC, useState } from 'react';
import { isEmpty, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

import { Layout } from '../../components/Layout';
import { getUser } from '../dbStore/dbStore';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { PhoneInput } from '../../components/PhoneInput';
import { useDispatchWithHistory } from '../actionWithHistory';
import { changePhone } from './userSaga';
import { routes } from '../../domain/routes';
import { useNavigate } from '../navigation/router';
import { CrossButton } from '../../components/CrossButton';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js/core';

export const ChangePhonePage: FC = () => {
  const navigate = useNavigate();
  const user = useSelector(getUser);
  const defaultPhone = !isUndefined(user) ? user.phone : '';
  const dispatch = useDispatchWithHistory();
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>(defaultPhone);
  const { t } = useTranslation('translation', { keyPrefix: 'changePhoneNumber' });

  const canUpdate = !isEmpty(phoneNumber);
  const sendCode = () => {
    dispatch(changePhone, {
      phoneNumber,
    });
  };

  const handleBack = () => {
    navigate(routes.profile);
  };

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendCode} isDisabled={!canUpdate}>
            {t('buttonLabel')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={handleBack} />

      <div className="l-row">
        <div className="c-title">{t('title')}</div>
      </div>
      <div className="l-row">
        <PhoneInput value={phoneNumber} onChange={setPhoneNumber} />
      </div>
    </Layout>
  );
};
