import { all } from 'redux-saga/effects';
import { signupSaga } from '../signup/signupSaga';
import { authSaga } from '../auth/authStore';
import { cardSaga } from '../card/cardStore';
import { userSaga } from '../user/userSaga';
import { globalSaga } from '../global/globalSaga';
import { loggerSaga } from '../logger';

export function* rootSaga() {
  yield all([signupSaga(), authSaga(), cardSaga(), userSaga(), globalSaga(), loggerSaga()]);
}
