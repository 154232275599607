import React from 'react';
import cn from 'classnames';
import { Control } from 'react-hook-form/dist/types/form';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { Path } from 'react-hook-form/dist/types/path';
import { Controller } from 'react-hook-form';
import { ControllerProps } from 'react-hook-form/dist/types/controller';

interface IProps<TFieldValues extends FieldValues = FieldValues, TContext = any> {
  placeholder?: string;
  label?: string;
  name: Path<TFieldValues>;
  control: Control<TFieldValues, TContext>;
  options: { value: string; label: string }[];
}
export const Select = <TFieldValues extends FieldValues = FieldValues, TContext = any>({
  label,
  name,
  control,
  options,
  ...props
}: IProps<TFieldValues, TContext> & Omit<ControllerProps<TFieldValues>, 'render'>) => {
  return (
    <Controller<TFieldValues>
      name={name}
      control={control}
      {...props}
      render={({ field, fieldState }) => (
        <div className={cn('c-input', fieldState.invalid && 'c-input--validation')}>
          <div className="c-input__message c-input__message--error">
            {fieldState.error?.message}
          </div>
          <label className="c-input__label">
            <select
              className="c-input__type"
              aria-invalid={fieldState.invalid ? 'true' : 'false'}
              {...field}
            >
              {options.map((option) => (
                <option key={`${option.label}`} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <span className="c-input__title">{label}</span>
          </label>
        </div>
      )}
    />
  );
};
