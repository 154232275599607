import { head, isUndefined } from 'lodash';
import normalize from 'json-api-normalizer';
import { put } from 'redux-saga/effects';

import { JsonApiResponseDTO } from '../boundries/responseDTO/jsonApi';
import { addRecords } from './dbStore/dbStore';

export type saveJsonApiResponseToDbFunction<T = unknown> = (data: JsonApiResponseDTO<T>) => void;

export function* saveJsonApiResponseToDb<T = unknown>(data: JsonApiResponseDTO<T>) {
  const innerData = head(data.data);
  if (isUndefined(innerData)) {
    throw Error("Can't save empty response");
  }
  const normalizedData = normalize(data);
  yield put(addRecords(normalizedData as any));
  return innerData.attributes;
}
