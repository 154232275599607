import { isIOS } from 'react-device-detect';
import { isNil } from 'lodash';

export const isNotificationsSupported = () => !isIOS && !isNil(Notification);

export const canShowNotifications = (permissions: NotificationPermission) =>
  permissions === 'granted';

export const isNotificationsAccepted = () =>
  isNotificationsSupported() && canShowNotifications(Notification.permission);

export const isNotificationsRejected = () =>
  isNotificationsSupported() && Notification.permission === 'denied';

export const isNotificationsViewed = () =>
  isNotificationsSupported() && Notification.permission !== 'default';
