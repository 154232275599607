import React, { FC, useState } from 'react';
import { isEmpty } from 'lodash';

import { Layout } from '../../components/Layout';
import { PhoneInput } from '../../components/PhoneInput';
import { ButtonWithLoading } from '../global/ButtonWithLoading';
import { getIsAuthenticated, signIn } from './authStore';
import { useDispatchWithHistory } from '../actionWithHistory';
import { Link, Navigate } from '../navigation/router';
import { signUpStartRoute } from '../../config/signupConfig';
import { useSelector } from 'react-redux';
import { routes } from '../../domain/routes';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js/core';

export const SignInPage: FC = () => {
  const dispatch = useDispatchWithHistory();
  const [phoneNumber, setPhoneNumber] = useState<E164Number | undefined>('');

  const canSignIn = !isEmpty(phoneNumber);
  const handleSignIn = () => {
    dispatch(signIn, { phone: phoneNumber });
  };
  const isAuth = useSelector(getIsAuthenticated);
  const { t } = useTranslation('translation', { keyPrefix: 'signIn.index' });

  if (isAuth) return <Navigate to={routes.profile} />;

  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={handleSignIn} isDisabled={!canSignIn}>
            {t('confirmationLabel')}
          </ButtonWithLoading>
        </footer>
      }
    >
      <div className="l-row">
        <div className="c-title">{t('header')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <div className="c-text">{t('text')}</div>
      </div>
      <div className="l-row l-row--mt-16">
        <PhoneInput onChange={setPhoneNumber} value={phoneNumber} />
      </div>
      <div className="l-row l-row--mt-16">
        <Link to={signUpStartRoute}>
          <button className="c-link">{t('buttonLabel')}</button>
        </Link>
      </div>
    </Layout>
  );
};
