import React, { FC, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { useAppDispatch } from '../../interface-adapters/store/hooks';
import { changeNotActivePhone } from '../../interface-adapters/user/userSaga';
import { signIn } from '../../interface-adapters/auth/authStore';
import { getIsLogged } from '../../interface-adapters/auth/selectors';
import { useDispatchWithHistory } from '../../interface-adapters/actionWithHistory';
import { Layout } from '../../components/Layout';
import { ButtonWithLoading } from '../../interface-adapters/global/ButtonWithLoading';
import { CrossButton } from '../CrossButton';
import { PhoneInput } from '../../components/PhoneInput';
import { useTranslation } from 'react-i18next';
import { E164Number } from 'libphonenumber-js/core';

const SEND_CODE_DELAY = 30;

interface Props {
  onCrossClick: () => void;
  onPhoneNumberSend: (phoneNumber: string) => void;
  defaultPhone: string;
}

export const PhoneNumberPage: FC<Props> = ({ onCrossClick, onPhoneNumberSend, defaultPhone }) => {
  const [phoneNumber, setPhoneNumber] = useState<E164Number>(defaultPhone);
  const dispatch = useAppDispatch();
  const dispatchWithHistory = useDispatchWithHistory();
  const defaultCountdownDelay = isEmpty(defaultPhone) ? 0 : SEND_CODE_DELAY;
  const [delay, setDelay] = useState(defaultCountdownDelay);
  const isLogged = useSelector(getIsLogged);
  const { t } = useTranslation('translation', { keyPrefix: 'changePhoneNumber' });

  const countdown = useCallback(() => {
    setDelay(delay - 1);
  }, [delay]);

  useEffect(() => {
    if (delay > 0) {
      window.setTimeout(countdown, 1000);
    }
  }, [delay, countdown]);

  const canUpdate = !isEmpty(phoneNumber) && delay === 0;
  const sendCode = () => {
    setDelay(SEND_CODE_DELAY);
    if (isLogged) {
      dispatch(changeNotActivePhone({ phoneNumber }));
    } else {
      dispatchWithHistory(signIn, { phone: phoneNumber });
    }
    onPhoneNumberSend(phoneNumber);
  };

  const countdownTitle = delay > 0 ? delay : '';
  return (
    <Layout
      footer={
        <footer className="l-footer">
          <ButtonWithLoading onClick={sendCode} isDisabled={!canUpdate}>
            {t('updateButtonLabel', { time: countdownTitle })}
          </ButtonWithLoading>
        </footer>
      }
    >
      <CrossButton onClick={onCrossClick} />
      <div className="l-row">
        <div className="c-title">{t('title')}</div>
      </div>
      <div className="l-row">
        <PhoneInput
          value={phoneNumber}
          onChange={(value) => {
            setPhoneNumber(value || '');
          }}
        />
      </div>
    </Layout>
  );
};
