import React, { FC } from 'react';
import { Input } from './Input';
import { InputProps } from './types';

const style = {
  fontSize: '16px',
  fontWeight: 600,
};

export const OtpInput: FC<Omit<InputProps, 'label'>> = (props): JSX.Element => {
  return (
    <Input
      inputOptions={{
        style,
        type: 'tel',
        autoComplete: 'one-time-code',
        maxLength: 6,
      }}
      label={''}
      {...props}
    />
  );
};
