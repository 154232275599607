import React, { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { isNull, isUndefined } from 'lodash';

import { routes } from '../../domain/routes';
import { Layout } from '../../components/Layout';
import { Loader } from '../../components/Loader';
import { getCard, getProfile } from '../dbStore/dbStore';
import { getBalanceHistory, getUserCard } from './userSaga';
import { useAppDispatch } from '../store/hooks';
import { getIsAuthenticated, logout } from '../auth/authStore';
import { downloadAndroidCardAction, downloadIOSCardAction } from '../card/cardStore';
import { InviteFriendButton } from './InviteFriendButton';
import { useRedirectOnAuthStepChangeEffct } from '../sharedHooks';
import { subscribeToPushManager } from '../subscriptionNotifications';
import { OptionalActivationStepsButton } from '../../components/OptionalActivationStepsButton/OptionalActivationStepsButton';
import { titles } from '../../config/titles';
import { BonusesBalance } from './BonusesBalance';
import { ButtonDownloadCard } from '../card/ButtonDownloadCard';
import { isUserAndroid, isUserIos } from './utils';
import cn from 'classnames';
import { isUserActionForPushSubscriptionNeeded } from '../../service-worker/notifications';
import { isNotificationsViewed } from '../../service-worker/helpers';
import { IMessageBus } from '@roketus/web-toolkit';
import { getDependency } from '../diContext';
import {
  DEMO_EMAIL_UPDATE_CLICK,
  DEMO_LOGOUT_CLICK,
  DEMO_NAME_UPDATE_CLICK,
  DEMO_PHONE_UPDATE_CLICK,
} from '../../domain/specs/analyticsActionCodes';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from '../navigation/router';
import RegisterServiceWorker from '../../service-worker/register';

const isWithPushButton = isUserActionForPushSubscriptionNeeded() && !isNotificationsViewed();

const { issuerTitle } = titles;
export const ProfilePage: FC = () => {
  const { t } = useTranslation('translation', { keyPrefix: 'profile' });

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const profile = useSelector(getProfile);
  const isAuth = useSelector(getIsAuthenticated);
  const card = useSelector(getCard);
  const isLoading = isUndefined(profile);

  const firstName: string = profile?.firstName || '',
    lastName: string = profile?.lastName || '',
    expirationDate: Date | null = card ? new Date(card.validTill * 1000) : null;

  const messageBus = getDependency('messageBus') as IMessageBus;

  useRedirectOnAuthStepChangeEffct();

  useEffect(() => {
    if (!isWithPushButton) {
      subscribeToPushManager();
    }
  }, []);

  useEffect(() => {
    if (isAuth) {
      dispatch(getUserCard());
    }
  }, [isAuth, dispatch]);

  useEffect(() => {
    if (isAuth) {
      dispatch(getBalanceHistory());
    }
  }, [isAuth, dispatch]);

  const downloadIOSCard = useCallback(() => {
    dispatch(downloadIOSCardAction());
  }, [dispatch]);

  const downloadAndroidCard = useCallback(() => {
    dispatch(downloadAndroidCardAction());
  }, [dispatch]);

  const handleOnUpdateProfileCLick = useCallback(() => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_NAME_UPDATE_CLICK,
      source: 'ProfilePage',
    });

    navigate(routes.profileChangeName);
  }, [navigate, messageBus]);

  const handleOnUpdatePhoneCLick = useCallback(() => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_PHONE_UPDATE_CLICK,
      source: 'ProfilePage',
    });

    navigate(routes.profileChangePhone);
  }, [messageBus, navigate]);

  const handleOnUpdateEmailCLick = useCallback(() => {
    messageBus.send({
      type: 'GAanalyticsEvent',
      message: DEMO_EMAIL_UPDATE_CLICK,
      source: 'ProfilePage',
    });

    navigate(routes.profileChangeEmail);
  }, [messageBus, navigate]);

  const handleOnSubscribeCLick = useCallback(() => {
    subscribeToPushManager();
  }, []);

  const isDateExpired = () => {
    if (isNull(expirationDate)) return false;
    return expirationDate.getTime() < new Date().getTime();
  };

  const runOnce = useRef(true);
  useEffect(() => {
    if (runOnce.current) {
      runOnce.current = false;
      RegisterServiceWorker();
    }
  }, []);

  return (
    <Layout>
      {isLoading && <Loader isActive={true} />}
      {!isLoading && (
        <>
          <div className="l-row">
            <div className="c-title">{t('title')}</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-title c-title--fs-14">{t('subtitle', { name: firstName })}</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-text">{t('mainText', { issuerTitle })}</div>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-line" />
          </div>
          <div className="l-row l-row--mt-16">
            <BonusesBalance />
          </div>
          <div className="l-row l-row--mt-16">
            {t('dateLabel')}
            <span className={cn('c-text', isDateExpired() && 'c-text--red')}>
              {expirationDate?.toISOString().split('T')[0]}
            </span>
          </div>
          <div className="l-row l-row--mt-16">
            <div className="c-card">
              <div className="c-card__name">
                {firstName} {lastName}
              </div>
              <div className="c-card__number">{card?.cardNumber}</div>
            </div>
          </div>

          <div className="l-row l-row--mt-16 c-download-buttons">
            <ButtonDownloadCard
              data-id="download-card-link"
              onClick={downloadIOSCard}
              device="iOS"
              data-display={isUserIos() ? 'button' : 'link'}
            >
              {t('downloadCardForIos')}
            </ButtonDownloadCard>

            <ButtonDownloadCard
              data-id="download-card-link"
              onClick={downloadAndroidCard}
              device="android"
              data-display={isUserAndroid() ? 'button' : 'link'}
            >
              {t('downloadCardForAndroid')}
            </ButtonDownloadCard>
          </div>

          <div className="l-row l-row--mt-16">
            <div className="c-get">
              <div className="l-row">
                <b>{t('referral.title', { issuerTitle })}</b>
              </div>
              <div className="l-row l-row--mt-16">
                <div className="c-text">
                  <Trans t={t} i18nKey="referral.text" value={{ issuerTitle }} />
                </div>
              </div>
              <div className="l-row l-row--mt-16">
                <InviteFriendButton />
              </div>
            </div>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-name-card-link"
              onClick={handleOnUpdateProfileCLick}
            >
              {t('updateName')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-phone-number-link"
              onClick={handleOnUpdatePhoneCLick}
            >
              {t('updatePhone')}
            </button>
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="update-email-link"
              onClick={handleOnUpdateEmailCLick}
            >
              {t('updateEmail')}
            </button>
            <div className="l-row l-row--mt-16">
              <OptionalActivationStepsButton />
            </div>
            {isWithPushButton && (
              <div className="l-row l-row--mt-16">
                <button className="c-link c-link--blue" onClick={handleOnSubscribeCLick}>
                  {t('subscribe')}
                </button>
              </div>
            )}
          </div>
          <div className="l-row l-row--mt-16">
            <button
              className="c-link c-link--blue"
              data-id="logout-link"
              onClick={() => {
                messageBus.send({
                  type: 'GAanalyticsEvent',
                  message: DEMO_LOGOUT_CLICK,
                  source: 'ProfilePage',
                });

                dispatch(logout());
              }}
            >
              {t('logout')}
            </button>
          </div>
        </>
      )}
    </Layout>
  );
};
