import { requestNotificationPermission } from './notifications';
import { logger, subscribeToSWMessages } from './logger';
import { windowLoadService } from '../interface-adapters/windowLoadService';

const swPath = `${process.env.PUBLIC_URL}/main.js`;
export default function RegisterServiceWorker() {
  if (!('serviceWorker' in navigator)) {
    console.log('ServiceWorker is not supported by browser. No worker in navigator.');
    return;
  }

  if (process.env.REACT_APP_ENVIRONMENT === 'local') {
    console.log('Skip service worker registration in development mode');
    return;
  }

  windowLoadService.state.subscribe((state) => {
    console.log(state);

    if (!state.isLoaded) {
      return;
    }

    navigator.serviceWorker.register(swPath, { type: 'module' }).then(
      function (registration) {
        // Registration was successful
        logger.log('ServiceWorker registration successful with scope: ', registration.scope);

        registration.update();
        subscribeToSWMessages();
        requestNotificationPermission();
      },
      function (err) {
        // registration failed :(
        logger.log('ServiceWorker registration failed: ', err);
      },
    );
  });
}
