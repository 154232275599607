import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { getActivation } from './dbStore/dbStore';
import { SignupStep } from '../domain/entities/signup';
import { signupPageByState } from './signup/consts';
import { JWT_AUTH } from '../domain/specs/storageKeys';
import { useNavigate, useLocation } from './navigation/router';

export const useRedirectOnAuthStepChangeEffct = () => {
  const activation = useSelector(getActivation);
  const navigate = useNavigate();
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<SignupStep>(SignupStep.Basic);

  useEffect(() => {
    //@todo think of better logic to not redirect user from 'success phone activation' page
    if (location.pathname === '/signup/phone-activation') return;

    if (location.pathname === '/profile' && activation?.nextStepSkippable) return;

    if (!activation) return;

    if (localStorage.getItem(JWT_AUTH)) {
      console.log('detecting jwt auth, skipping redirect');

      return;
    }

    if (activation.completed || activation.nextStep !== currentStep) {
      setCurrentStep(activation.nextStep);
      const redirectUrl = signupPageByState[activation.nextStep];

      console.log('user is active or not finished registration, Redirecting to: ' + redirectUrl);
      navigate(redirectUrl);
    }
  }, [activation, currentStep, navigate, location.pathname]);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};
